<template>
  <form
    ref="form"
    @submit.prevent="next"
  >
    <c-title>Antragstellung</c-title>

    <p class="mt-8 mb-8">
      Alle Felder außer den explizit ausgenommenen sind Pflichtfelder.
    </p>
    <c-form-text
      v-model="fields.name"
      :errors="errors.name"
      class="mb-8"
      label="Name Kultureinrichtung/Künstler*in/Künstler*innengruppe"
      autocomplete="honorific-suffix"
      required
    />
    <c-form-text
      v-model="fields.legal_form"
      :errors="errors.legal_form"
      class="mb-8"
      label="Rechtsform (gilt nur für Kultureinrichtungen)"
      autocomplete="honorific-suffix"
    />
    <c-form-text
      v-model="fields.contact_person"
      :errors="errors.contact_person"
      class="mb-8"
      label="Ansprechpartner*in"
      autocomplete="honorific-suffix"
      required
    />
    <c-form-text
      v-model="fields.address"
      :errors="errors.address"
      class="mb-8"
      label="Straße und Hausnummer"
      autocomplete="street-address"
      maxlength="100"
      required
    />
    <c-form-columns :columns="[1, 5]">
      <c-form-text
        v-model="fields.postal_code"
        :errors="errors.postal_code"
        class="mb-8"
        label="Postleitzahl"
        autocomplete="postal-code"
        inputmode="numeric"
        pattern="[0-9]*"
        minlength="5"
        maxlength="5"
        required
      />
      <c-form-text
        v-model="fields.city"
        :errors="errors.city"
        class="mb-8"
        label="Ort"
        autocomplete="address-level2"
        maxlength="100"
        required
      />
    </c-form-columns>
    <c-form-text
      v-model="fields.contact_number"
      :errors="errors.contact_number"
      class="mb-8"
      label="Telefonnummer"
      autocomplete="honorific-suffix"
      required
    />
    <c-form-text
      v-model="fields.email"
      :errors="errors.email"
      class="mb-8"
      label="E-Mail-Adresse"
      type="email"
      autocomplete="email"
      required
    />
    <c-form-text
      v-model="fields.website"
      :errors="errors.website"
      class="mb-8"
      label="Website/Internetauftritt (optional)"
      autocomplete="honorific-suffix"
      type="url"
      placeholder="https://example.com"
    />
    <c-form-text
      v-model="fields.project_title"
      :errors="errors.project_title"
      class="mb-8"
      label="Titel des Vorhabens"
      autocomplete="honorific-suffix"
      required
    />
    <c-form-text
      v-model="fields.start_period"
      :errors="errors.start_period"
      class="mb-8"
      label="Beginn, Zeitraum"
      required
    />
    <c-form-text
      v-model="fields.application_amount"
      :errors="errors.application_amount"
      class="mb-8"
      label="Antragssumme"
      autocomplete="honorific-suffix"
      required
    />
    Bitte laden Sie unter Punkt 1. das Antragsformular (PDF) einzeln hoch.
    <c-form-text
      :errors="errors.application_file"
      class="mt-5 mb-8"
      type="file"
      :file="fields.application_file"
      label="1. Antragsformular (ausgefülltes PDF-Formular)"
      :help="`Erlaubte Dateiendungen: PDF, DOC, DOCX, XLS und XLSX. Maximale Dateigröße: 5 MB.`"
      accept=".pdf,.doc,.docx,.xls,.xlsx"
      required
      @change="fields.application_file = $event"
    />
    Die weiteren erforderlichen Unterlagen (2. bis 5.) können Sie jeweils einzeln hochladen oder zu einem Dokument zusammengefasst unter Punkt 2.
    <c-form-text
      :errors="errors.concept_file"
      class="mt-5 mb-8"
      type="file"
      :file="fields.concept_file"
      label="2. Konzept (oder zusammengefasste Unterlagen)"
      :help="`Erlaubte Dateiendungen: PDF, DOC, DOCX, XLS und XLSX. Maximale Dateigröße: 5 MB.`"
      accept=".pdf,.doc,.docx,.xls,.xlsx"
      required
      @change="fields.concept_file = $event"
    />
    <c-form-text
      :errors="errors.vitae_project_participants"
      class="mt-5 mb-8"
      type="file"
      :file="fields.vitae_project_participants"
      label="3. Lebensläufe der Beteiligten"
      :help="`Erlaubte Dateiendungen: PDF, DOC, DOCX, XLS und XLSX. Maximale Dateigröße: 5 MB.`"
      accept=".pdf,.doc,.docx,.xls,.xlsx"
      @change="fields.vitae_project_participants = $event"
    />
    <c-form-text
      :errors="errors.cost_plan_file"
      class="mt-5 mb-8"
      type="file"
      :file="fields.cost_plan_file"
      label="4. Kostenplan"
      :help="`Erlaubte Dateiendungen: PDF, DOC, DOCX, XLS und XLSX. Maximale Dateigröße: 5 MB.`"
      accept=".pdf,.doc,.docx,.xls,.xlsx"
      @change="fields.cost_plan_file = $event"
    />
    <c-form-text
      :errors="errors.work_samples_file"
      class="mt-5 mb-8"
      type="file"
      :file="fields.work_samples_file"
      label="5. Arbeitsproben"
      :help="`Erlaubte Dateiendungen: PDF, DOC, DOCX, XLS und XLSX. Maximale Dateigröße: 5 MB.`"
      accept=".pdf,.doc,.docx"
      @change="fields.work_samples_file = $event"
    />
    <c-button>
      Nächster Schritt
    </c-button>
  </form>
</template>

<script>
import BRequestStep from './BRequestStep.vue'

export default {
  name: 'VRequestStep0',

  extends: BRequestStep,

  data () {
    return {
      fields: {
        name: '',
        legal_form: '',
        contact_person: '',
        address: '',
        contact_number: '',
        email: '',
        website: '',
        project_title: '',
        message_text: '',
        start_period: '',
        application_amount: '',
        application_file: '',
        concept_file: '',
        vitae_project_participants: '',
        cost_plan_file: '',
        work_samples_file: ''
      }
    }
  },

  computed: {
    step: () => 0,
    id: () => 'reportData'
  }
}
</script>
